import "../Custom.css";
import {
  INT_COMMERCE_PAGE_IMAGE_FIVE,
  INT_COMMERCE_PAGE_IMAGE_FOUR,
  INT_COMMERCE_PAGE_IMAGE_ONE,
  INT_COMMERCE_PAGE_IMAGE_SIX,
  INT_COMMERCE_PAGE_IMAGE_THREE,
  INT_COMMERCE_PAGE_IMAGE_TWO,
} from "../utils/constants";

const IntCommerce = () => {
  return (
    <div className="container pb-5">
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="row p-2 animated animatedFadeInUp fadeInUp animation-delay3">
            <h1
              className="fw-bold d-none d-md-block text-secondary text-black"
              style={{ fontSize: "80px" }}
            >
              Check what we do<span className="text-warning">.</span>
            </h1>
            <h1
              className="fw-bold d-block d-md-none text-secondary text-black"
              style={{ fontSize: "40px" }}
            >
              Check what we do<span className="text-warning">.</span>
            </h1>
          </div>

          {/* <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay3">
            <img
              src={INT_COMMERCE_PAGE_IMAGE_ONE}
              className="transform"
              alt="oud"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay4">
            <p className="fw-bold text-secondary">Oud</p>
            <p style={{ color: "#495057" }}>
              <small>
                Discover authentic oud oil and natural oud sticks sourced for
                excellence. Immerse in Agarwood's luxurious essence—a curated
                sensory experience for discerning connoisseurs seeking quality
                and refinement.
              </small>
            </p>
          </div> */}

          {/* <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay3">
            <img
              src={INT_COMMERCE_PAGE_IMAGE_TWO}
              className="transform"
              alt="copper"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay4">
            <p className="fw-bold text-secondary">Copper</p>
            <p style={{ color: "#495057" }}>
              <small>
                The timeless allure of copper shines through our curated
                selection. Specializing in premium copper products sourced for
                excellence, offering durability and sophistication to elevate
                your space.
              </small>
            </p>
          </div> */}

          {/* <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay3">
            <img
              src={INT_COMMERCE_PAGE_IMAGE_THREE}
              className="transform"
              alt="aluminium"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay4">
            <p className="fw-bold text-secondary">Aluminium</p>
            <p style={{ color: "#495057" }}>
              <small>
                {" "}
                Explore our curated range of high-quality aluminum products
                sourced for excellence. Elevate your projects with durable and
                versatile aluminum, tailored to meet your needs and enhance your
                endeavors.
              </small>
            </p>
          </div> */}
        </div>

        <div className="col-12 col-md-6">
          {/* <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay5">
            <img
              src={INT_COMMERCE_PAGE_IMAGE_FOUR}
              className="transform"
              alt="gold"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay6">
            <p className="fw-bold text-secondary">Gold</p>
            <p style={{ color: "#495057" }}>
              <small>
                Discover meticulously sourced gold offerings, from
                investment-grade options to exquisite pieces. Experience
                timeless elegance and enduring value with our curated selection,
                tailored to elevate your style and investments.
              </small>
            </p>
          </div> */}

          {/* <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay5">
            <img
              src={INT_COMMERCE_PAGE_IMAGE_FIVE}
              className="transform"
              alt="steel"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay6">
            <p className="fw-bold text-secondary">Steel</p>
            <p style={{ color: "#495057" }}>
              <small>
                Elevate your projects with our premium steel offerings,
                meticulously sourced for strength and durability. We specialize
                in high-quality steel products, providing reliability and
                versatility for your diverse needs.
              </small>
            </p>
          </div> */}

          <div className="row p-5 animated animatedFadeInUp fadeInUp animation-delay5">
            <img
              src={INT_COMMERCE_PAGE_IMAGE_SIX}
              className="transform"
              alt="clothing"
              height="auto"
              width="auto"
            />
          </div>
          <div className="row px-5 animated animatedFadeInUp fadeInUp animation-delay6">
            <p className="fw-bold text-secondary">Clothing & Accessories</p>
            <p style={{ color: "#495057" }}>
              <small>
                Experience curated fashion and accessories sourced for
                excellence. Elevate your style with our diverse selection,
                offering trendy apparel and accessories crafted to enhance your
                unique personality and preferences.
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntCommerce;
