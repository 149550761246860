// HOMEPAGE

export const HOMEPAGE_MAIN_BANNER = process.env.PUBLIC_URL + "/images/homepage-main-banner.jpeg";

export const HOMEPAGE_SECOND_BANNER = process.env.PUBLIC_URL + "/images/homepage-second-banner.jpeg";

export const HOMEPAGE_INT_COMMERCE_BANNER = process.env.PUBLIC_URL + "/images/international-commerce.jpeg";

export const HOMEPAGE_ACCOUNTING_SERVICE_BANNER = process.env.PUBLIC_URL + "/images/accounting.jpg";

export const HOMEPAGE_CONSULTANTS_SERVICE_BANNER = process.env.PUBLIC_URL + "/images/business-consultants.jpeg";

export const HOMEPAGE_DIGITAL_SERVICE_BANNER = process.env.PUBLIC_URL + "/images/digital-solutions.jpeg";

// ABOUT PAGE

export const COMPANY_CEO_AVATAR = "";

// INTERNATIONAL COMMERCE PAGE

export const INT_COMMERCE_PAGE_IMAGE_ONE = process.env.PUBLIC_URL + "/images/agarwood.jpeg";

export const INT_COMMERCE_PAGE_IMAGE_TWO = process.env.PUBLIC_URL + "/images/copper.jpeg";

export const INT_COMMERCE_PAGE_IMAGE_THREE = process.env.PUBLIC_URL + "/images/aluminium.jpeg";

export const INT_COMMERCE_PAGE_IMAGE_FOUR = process.env.PUBLIC_URL + "/images/gold.jpeg";

export const INT_COMMERCE_PAGE_IMAGE_FIVE = process.env.PUBLIC_URL + "/images/steel.jpeg";

export const INT_COMMERCE_PAGE_IMAGE_SIX = process.env.PUBLIC_URL + "/images/clothing.jpeg";

// ACCOUNTING_SERVICE & BOOKKEEPING PAGE

export const ACCOUNTING_SERVICE_PAGE_IMAGE_ONE = process.env.PUBLIC_URL + "/images/tax-registration.jpeg";

export const ACCOUNTING_SERVICE_PAGE_IMAGE_TWO = process.env.PUBLIC_URL + "/images/return-filing.jpg";

export const ACCOUNTING_SERVICE_PAGE_IMAGE_THREE = process.env.PUBLIC_URL + "/images/consultancy.jpeg";

export const ACCOUNTING_SERVICE_PAGE_IMAGE_FOUR = process.env.PUBLIC_URL + "/images/accounting-bookkeeping.jpeg";

// OVERSEAS BUSINESS CONSULTANT PAGE

export const CONSULTANTS_SERVICE_PAGE_IMAGE_ONE = process.env.PUBLIC_URL + "/images/entry-strategy.jpeg"

export const CONSULTANTS_SERVICE_PAGE_IMAGE_TWO = process.env.PUBLIC_URL + "/images/compliance-assistance.jpeg"

export const CONSULTANTS_SERVICE_PAGE_IMAGE_THREE = process.env.PUBLIC_URL + "/images/cultural-adaptation.jpeg"

export const CONSULTANTS_SERVICE_PAGE_IMAGE_FOUR = process.env.PUBLIC_URL + "/images/ongoing-support.jpeg"

// DIGITAL SOLUTION PAGE

export const DIGITAL_SERVICE_PAGE_IMAGE_ONE = process.env.PUBLIC_URL + "/images/web-development.jpeg";

export const DIGITAL_SERVICE_PAGE_IMAGE_TWO = process.env.PUBLIC_URL + "/images/app-development.jpeg";

export const DIGITAL_SERVICE_PAGE_IMAGE_THREE = process.env.PUBLIC_URL + "/images/digital-marketing.png";

export const DIGITAL_SERVICE_PAGE_IMAGE_FOUR = process.env.PUBLIC_URL + "/images/graphic-designing.jpeg";

// CONTACT PAGE

export const COMPANY_CONTACT_ADDRESS =
  "608, Metropolis Tower, Business Bay, Dubai - UAE";

export const COMPANY_TIMINGS = "10:00 AM - 06:00 PM";

export const COMPANY_WORKING_DAYS = "Monday - Friday";

export const COMPANY_CONTACT_PHONE = "+971 042686404";

export const COMPANY_CONTACT_EMAIL = "info@morifar.com";

export const COMPANY_GOOGLE_MAPS =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1559.9730957998836!2d55.275184949826006!3d25.185146538458497!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6831be402d13%3A0x78f846d645b2ec12!2sBusiness%20Bay!5e0!3m2!1sen!2sin!4v1736759738765!5m2!1sen!2sin";
